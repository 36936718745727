<template>
  <va-inner-loading :loading="loading" icon="restart_alt">
    <va-card stripe stripe-color="#013c84" class="mt-2">
      <va-card-title>{{ $t("general_info") }}</va-card-title>
      <va-card-content class="p-5" style="margin-bottom: 25px">
        <div
          class="row align--center px-5 justify--space-between"
          style="padding: 25px"
        >
          <div style="margin: 0; display: flex">
            <va-avatar
              style="width: 6rem; height: 6rem"
              size="large"
              font-size="24px"
              :src="$store.state.imgUrl + team.logo"
            />
            <div class="col justify--center">
              <h1
                class="text-center px-2"
                style="font-size: 2rem; display: inline"
              >
                {{ team.name }}
              </h1>
              <h6
                class="text-center text-opacity-50 px-3"
                style="color: #626262"
                v-if="team.city && team.area"
              >
                {{ `${team.city} , ${team.area}` }}
              </h6>
              <div class="social">
                <a
                  target="_blank"
                  v-if="team.facebook_id"
                  :href="`https://www.facebook.com/${team.facebook_id}`"
                  style="color: #395498"
                  class="fa fa-facebook-square"
                  aria-hidden="true"
                ></a>
                <a
                  target="_blank"
                  v-if="team.twitter_id"
                  :href="`https://www.twitter.com/${team.twitter_id}`"
                  style="color: #5aa4d6"
                  class="fa fa-twitter-square"
                  aria-hidden="true"
                ></a>
                <a
                  target="_blank"
                  v-if="team.website_url"
                  :href="team.website_url"
                  class="fa fa-globe"
                  aria-hidden="true"
                ></a>
              </div>
            </div>
          </div>
          <div class="col justify--center">
            <div class="flex align-items-end">
              {{ team.members_count }}
              <span class="material-icons">groups</span>
            </div>
            <div class="flex align-items-end">
              {{ team.fans_count }}
              <span class="material-icons" style="color: red">favorite</span>
            </div>
          </div>
        </div>

        <div class="row md6 justify--space-around" style="padding: 25px">
          <p style="line-height: 2.2">
            <strong>{{ $t("manager") }} </strong> : {{ team.manager || "________" }}
            <br />
            <strong>{{ $t("coach") }} </strong> : {{ team.coach || "_______" }}
            <br />
          </p>
          <div style="line-height: 2.2"></div>
        </div>
      </va-card-content>
    </va-card>
  </va-inner-loading>
</template>

<script>
import { gql, request } from "graphql-request";
export default {
  name: "team-info",
  data() {
    return {
      team: {},
      loading: true,
    };
  },
  async created() {
    try {
      const GET_TEAM_INFO = gql`
        query ($id: Int) {
          team: Teams(where: { id: $id }) {
            name
            logo
            city
            area
            twitter_id
            facebook_id
            website_url
            creator_id
            manager_id
            coach_id
            team_fans {
              id
            }
            team_members {
              id
            }
          }
        }
      `;
      const response = await request(this.$store.state.appUrl, GET_TEAM_INFO, {
        id: Number(this.$route.params.id),
      });
      if (response.team.length > 0) {
        this.team = response.team[0];
        this.team.fans_count = this.team.team_fans.length;
        this.team.members_count = this.team.team_members.length;

        if (this.team.manager_id) {
          const GET_MANAGER_NAME = gql`
            query ($id: Int) {
              manager: Users(id: $id) {
                name
              }
            }
          `;
          const managerResponse = await request(
            this.$store.state.appUrl,
            GET_MANAGER_NAME,
            {
              id: Number(this.team.manager_id),
            }
          );
          this.team.manager = managerResponse.manager[0].name;
        }
        if (this.team.coach_id) {
          const GET_COACH_NAME = gql`
            query ($id: Int) {
              coach: Users(id: $id) {
                name
              }
            }
          `;
          const coachResponse = await request(
            this.$store.state.appUrl,
            GET_COACH_NAME,
            {
              id: Number(this.team.coach_id),
            }
          );
          this.team.coach = coachResponse.coach[0].name;
        }

        this.loading = false;
      } else {
        this.$router.replace({ name: "not-found-large-text" });
      }
    } catch (error) {
      console.log(error);
      this.loading = false;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("errors.network"),
          text: "Try Again in a minute",
          confirmButtonColor: "#023b81",
          confirmButtonText: "Reload",
        })
        .then((result) => {
          if (result.value) {
            location.reload();
          }
        });
    }
  },
};
</script>

<style>
.col {
  display: flex;
  flex-direction: column;
}
.social {
  display: flex;
  justify-content: space-evenly;
  padding-top: 0.625rem;
}
</style>
