<template>
  <!-- Breadcrumb  -->
    <va-card class="row align--center">
        <va-card-content> 
        <va-breadcrumbs color="primary">
            <va-breadcrumbs-item  :to="{name : 'teams'}" :label="$t('menu.teams')" />
            <va-breadcrumbs-item  :label="team_name" />
        </va-breadcrumbs>
        </va-card-content>
    </va-card>
<!-- Content  -->
    <va-tabs grow hide-slider v-model="selectedTab" class="mt-5">
        <template #tabs>
            <va-tab name="info">
                <span class="material-icons">info</span>
                <h6 style="padding:0 10px">{{ $t('info') }}</h6>
            </va-tab>


            <va-tab name="members">
                <span class="material-icons">groups</span>
                <h6 style="padding:0 10px">{{ $t('team_members') }}</h6>        
            </va-tab>

            <va-tab name="stats">
                <span class="material-icons">analytics</span>
                <h6 style="padding:0 10px">{{ $t('stats') }}</h6>
            </va-tab>

        </template>

        <div v-if="selectedTab=='info'">
            <team-info/>
        </div>
        <div v-else-if="selectedTab == 'members'">
            <team-members/>
        </div>
        <div v-else>
            <team-stats/>
        </div>
    </va-tabs>
</template>

<script>
import { gql,request } from 'graphql-request'

import TeamInfo from './components/TeamInfo.vue';
import TeamMembers from './components/TeamMembers.vue';
import TeamStats from './components/TeamStats.vue';
    
export default {
    components:{
        TeamInfo,
        TeamMembers,
        TeamStats
    },
    data() {
        return {
            selectedTab:'info',
            team_name:''
        }
    },
    async mounted() {
        const GET_TEAM_NAME = gql`
        query($id: Int) {
            Teams(where: { id: $id }) {
                name        
            }
        }`;
        const response = await request(this.$store.state.appUrl, GET_TEAM_NAME, {id: Number(this.$route.params.id)});
        if(response.Teams.length>0){

            this.team_name = response.Teams[0].name;
        }
    }
}
</script>

<style>

</style>