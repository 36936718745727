<template>
  <va-inner-loading :loading="loading" icon="restart_alt">
    <va-card class="mt-4" stripe stripe-color="#595cff">
      <va-card
        v-if="stats.played == 0"
        class="row align--center mx-3"
        style="height: 35vh"
        stripe
        stripe-color="#1f1f62"
      >
        <va-card-content>
          <h1 class="text-center" style="font-size: 32px">
            {{ $t("no_stats") }}
            <va-icon class="material-icons" size="32px"> analytics </va-icon>
          </h1>
        </va-card-content>
      </va-card>
      <div v-else class="row px-3 justify--space-evenly">
        <div class="flex sm12 md6 lg8 my-2">
          <va-card class="mt-2" gradient color="#f0f0f0" outlined>
            <va-card-content class="text-large">{{ stats.played }}</va-card-content>
            <va-chart
              v-if="getPieChartData !== null"
              class="chart chart--donut pa-5"
              :data="getPieChartData"
              :options="pieChartOptions"
              type="pie"
            />
          </va-card>
        </div>
        <div class="flex sm12 md6 lg4 my-2">
          <va-card class="mt-2" gradient color="#f0f0f0" outlined>
            <va-card-content class="col justify--space-around">
              <div>
                <h2 class="py-4">{{ $t("top_scorer") }}</h2>
                <div
                  v-for="(goal, player) in topScorer"
                  :key="player"
                  style="line-height: 2"
                  class="row justify--space-between px-2 py-3"
                >
                  <strong> {{ player }} </strong>
                  <strong> {{ goal }} </strong>
                </div>
              </div>
            </va-card-content>
          </va-card>
          <va-card class="mt-2" gradient color="#f0f0f0" outlined>
            <va-card-content>
              <div class="row justify--space-evenly align--center py-3">
                <strong>{{ stats.cards?.yellow_cards }}</strong>
                <img src="/img/yellow.png" style="width: 32px" alt="" />
              </div>
              <div class="row justify--space-evenly align--center py-3">
                <strong>{{ stats.cards?.red_cards }}</strong>
                <img src="/img/red.png" style="width: 32px" alt="" />
              </div>
            </va-card-content>
          </va-card>
          <va-card class="mt-2" gradient color="#f0f0f0" outlined>
            <va-card-content>
              <div class="row justify--space-evenly align--center py-3">
                <strong>{{ stats.goals?.scored }}</strong>
                <img src="/img/goalFor.png" style="width: 32px" alt="" />
              </div>
              <div class="row justify--space-evenly align--center py-3">
                <strong>{{ stats.goals?.conceded }}</strong>
                <img src="/img/goalAgainst.png" style="width: 32px" alt="" />
              </div>
            </va-card-content>
          </va-card>
        </div>
      </div>
    </va-card>
  </va-inner-loading>
</template>

<script>
import { gql, request } from "graphql-request";
import {
  TEAM_MATCHES_STATS,
  TEAM_GOALS,
  TEAM_CARDS,
  TEAM_WINNED_CHAMPS,
} from "@/graphql/queries/stats";
import VaChart from "@/components/va-charts/VaChart.vue";
import { generateStats } from "@/services/stats";

export default {
  name: "team-stats",
  components: { VaChart },
  data() {
    return {
      topScorer: {},
      stats: {},
      getPieChartData: {},
      pieChartOptions: null,
      loading: true,
    };
  },
  async created() {
    try {
      this.getTopScorer();
      this.getStats();
      const response = await request(
        this.$store.state.appUrl,
        TEAM_MATCHES_STATS,
        {
          id: Number(this.$route.params.id),
          date: new Date().toISOString(),
        }
      );
      this.stats.played = response.played.count;
      this.stats.win = response.win.count;
      this.stats.loss = response.loss.count;
      this.stats.draw = response.draw.count;

      this.stats.winPercentage = `${
        (this.stats.win * 100) / this.stats.played
      }%`;

      this.pieChartOptions = {
        title: {
          display: true,
          text: this.$t("matches"),
          fontColor: "#34495e",
          fontFamily: "El Messiri",
          fontSize: 13,
          padding: 5,
          usePointStyle: true,
        },
        animation: {
          duration: 1500,
          easing: "easeInOutCirc",
          onProgress: function (animation) {
            // progress.value = animation.animationObject.currentStep / animation.animationObject.numSteps;
          },
        },
      };

      this.getPieChartData = {
        labels: [this.$t("win"), this.$t("draw"), this.$t("loss")],
        datasets: [
          {
            fillColor: this.gradient,
            backgroundColor: ["#199d00cc", "#013c84cc", "#c70000cc"],
            data: [this.stats.win, this.stats.draw, this.stats.loss],
          },
        ],
      };
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("errors.network"),
          text: "Try Again in a minute",
          confirmButtonColor: "#023b81",
          confirmButtonText: "Reload",
        })
        .then((result) => {
          if (result.value) {
            location.reload();
          }
        });
    }
  },
  methods: {
    async getTopScorer() {
      const GET_PLAYERS_EVENTS = gql`
        query ($team_id: Int) {
          players: MatchEvents(
            where: { team_id: $team_id, match_event_type_id: 14 }
          ) {
            user {
              id
              name
            }
          }
        }
      `;
      const response = await request(
        this.$store.state.appUrl,
        GET_PLAYERS_EVENTS,
        { team_id: +this.$route.params.id }
      );
      // get an object with player and goal like { player_name : 2 ,player_name : 5 }
      response.players.forEach((player) => {
        this.topScorer[player.user.name] =
          (this.topScorer[player.user.name] || 0) + 1;
      });
      // Sorting the object by the most goals
     this.topScorer = Object.entries(this.topScorer)
    .sort(([,a],[,b]) => a-b).reverse()
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    },
    async getStats() {
      let response = await request(this.$store.state.appUrl, TEAM_GOALS, {
        team_id: +this.$route.params.id,
      });
      this.stats.goals = generateStats(response.goals);
      response = await request(this.$store.state.appUrl, TEAM_CARDS, {
        team_id: +this.$route.params.id,
      });
      this.stats.cards = generateStats(response.cards);
    },
  },
};
</script>

<style>
.md3 {
  width: 20vw;
  height: fit-content;
}
/* .top-scorer {
  position: relative;
  background-image: url("/img/kick.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.top-scorer::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
} */
.top-scorer h2,
.top-scorer > div {
  color: #fff;
  z-index: 10;
}
</style>
