<template>
  <va-inner-loading :loading="loading" icon="restart_alt">
    <div class="container mx-2 mt-4">
      <div v-if="players.length == 0">
        <va-card
          class="row align--center"
          style="height: 35vh"
          stripe
          stripe-color="#1f1f62"
        >
          <va-card-content>
            <h1 class="text-center" style="font-size: 32px">
              {{ $t("no_players") }}
              <va-icon class="material-icons" size="32px"> person_off </va-icon>
            </h1>
          </va-card-content>
        </va-card>
      </div>
      <div v-else class="flex">
        <va-card stripe stripe-color="primary">
          <va-card-content>
            <va-list>
              <va-list-item
                v-for="(player, index) in players"
                :key="index"
                :class="playerPosition(player)"
                to=""
              >
                <va-list-item-section avatar class="px-3">
                  <va-avatar
                    style="overflow: hidden !important"
                    :class="player.user?.img ? 'unset-bg' : ''"
                    size="large"
                    font-size="24px"
                  >
                    <img
                      v-if="player.user?.img"
                      :src="$store.state.imgUrl + player.user.img"
                      width="100"
                    />
                    <div v-else>{{ player.user?.name.substring(0, 1) }}</div>
                  </va-avatar>
                </va-list-item-section>

                <va-list-item-section>
                  <va-list-item-label>
                    {{ player.user?.name }}
                  </va-list-item-label>

                  <va-list-item-label caption :lines="index + 1">
                    {{ player.position }}
                  </va-list-item-label>
                </va-list-item-section>

                <va-list-item-section icon>
                  <h1>{{ player.squad_number }}</h1>
                </va-list-item-section>
              </va-list-item>
            </va-list>
          </va-card-content>
        </va-card>
      </div>
    </div>
  </va-inner-loading>
</template>

<script>
import { gql, request } from "graphql-request";
export default {
  name: "team-members",
  data() {
    return {
      players: [],
      loading: true,
    };
  },
  async created() {
    const TEAM_MEMBERS = gql`
      query ($id: Int) {
        players: TeamMembers(where: { team_id: $id }) {
          position: player_position
          squad_number
          user {
            name
            img
          }
        }
      }
    `;

    try {
      const response = await request(this.$store.state.appUrl, TEAM_MEMBERS, {
        id: Number(this.$route.params.id),
      });
      this.players = [
        ...response.players.filter((y) => y.position == "FA"),
        ...response.players.filter((y) => y.position == "MF"),
        ...response.players.filter((y) => y.position == "DF"),
        ...response.players.filter((y) => y.position == "GK"),
      ];
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("errors.network"),
          text: "Try Again in a minute",
          confirmButtonColor: "#023b81",
          confirmButtonText: "Reload",
        })
        .then((result) => {
          if (result.value) {
            location.reload();
          }
        });
    }
  },
  methods: {
    playerPosition(player) {
      if (this.$i18n.locale == "sa") {
        if (player.position == "FA") return "forward ar";
        if (player.position == "MF") return "midfielder ar";
        if (player.position == "DF") return "defender ar";
        if (player.position == "GK") return "goalkeeper ar";
      }
      if (player.position == "FA") return "forward";
      if (player.position == "MF") return "midfielder";
      if (player.position == "DF") return "defender";
      if (player.position == "GK") return "goalkeeper";
    },
  },
};
</script>

<style scoped>
.unset-bg {
  background: unset !important;
}
.forward {
  border-left: 5px solid #c8102e;
}
.midfielder {
  border-left: 5px solid #4caf50;
}
.defender {
  border-left: 5px solid #012169;
}
.goalkeeper {
  border-left: 5px solid #ff9800;
}

.forward.ar {
  border-left: 0;
  border-right: 5px solid #c8102e;
}
.midfielder.ar {
  border-left: 0;
  border-right: 5px solid #4caf50;
}
.defender.ar {
  border-left: 0;
  border-right: 5px solid #012169;
}
.goalkeeper.ar {
  border-left: 0;
  border-right: 5px solid #ff9800;
}
</style>
